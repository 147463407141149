import { useTranslation } from "react-i18next";

import Button from "~/components/atoms/Button";
import { Icon } from "~/components/atoms/Icons";
import { PropsSVG } from "~/components/atoms/Icons/types";
import Typo from "~/components/atoms/Typo";
import { cn } from "~/utilities/cn";
import { ETypoColor } from "~/utilities/enums/Colors";
import { EIcon } from "~/utilities/enums/Icons";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";

export interface ButtonResizeProps {
  className: string;
  variant: "short" | "long";
  onClick?: () => void;
  text?: string;
  icon?: (props: PropsSVG) => JSX.Element;
}

const ButtonResize: React.FC<ButtonResizeProps> = ({
  icon,
  className,
  variant,
  onClick,
  text,
}) => {
  const { t } = useTranslation();

  const buttonClass = cn(
    "group overflow-hidden w-10.5 h-10.5 gap-x-2 transition-all duration-400 ease-in-out p-3 hover:bg-opacity-100 py-0 border-white",
    variant === "short"
      ? "items-center justify-end hover:w-152 hover:pl-3"
      : "w-fit",
    className
  );

  const textClass = cn(
    variant === "short"
      ? "hidden group-hover:block transition-all duration-400 ease-in-out text-white absolute overflow-hidden group-hover:text-black group-hover:relative"
      : "transition-none"
  );

  return (
    <Button
      size="icon"
      variant={variant === "short" ? "roundShadow" : "white"}
      className={buttonClass}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        if (typeof onClick === "function") onClick();
      }}
    >
      <Typo
        tag={ETypoTag.P}
        variant={ETypoVariant.SUB_TITLE_14}
        color={ETypoColor.TEXT}
        className={textClass}
      >
        {text ?? t("add_to_compare")}
      </Typo>
      <div>
        <Icon
          TypeIcon={icon ?? EIcon.PlusIcon}
          height={20}
          width={20}
          className="translate-x-px"
        />
      </div>
    </Button>
  );
};

export default ButtonResize;
